import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class MapMarker extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      marker
    } = props;
    this.state = {
      marker: marker
    };
  } a

  render() {
    const { marker } = this.state;
    const imageContext = require.context("../images/about-us", true);

    return (

      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-inner-map" id="tooltip-inner-map">
            {marker.countries.map((item, id) => (
              <div className="tooltip-map-item d-flex justify-content-center align-items-center mb-1" key={id}>
                <img className="small-img" src={imageContext('./' + item.icon)} alt="small-img" />
                <div className="title">{item.country}</div>
              </div>
            ))}
          </Tooltip>
        } >
        <div className={`marker ${this.props.className}`} />
      </OverlayTrigger>
    );
  };
}

export default MapMarker;
