import React from 'react';
import DeviceDetect from '../../components/DeviceDetect';
import { createMarkup } from '../../helpers/createMarkup';
import i18n from '../../services/translation/i18next';
import TimelineTabs from './parts/TimelineTabs';

const Timeline = () => {
  const stepper = i18n.t('aboutUs.stepper', { returnObjects: true });

  const imageContext = require.context('../../images/about-us', true);

  return (
    <div className="container">
      <DeviceDetect.Desktop>
        <div className="row">
          <TimelineTabs stepper={stepper} />
        </div>
      </DeviceDetect.Desktop>
      <DeviceDetect.UniversalTable>
        {stepper.map((item, id) => (
          <div className="tab-item-content" key={id}>
            <img
              className="img-fluid"
              src={imageContext('./' + item.icon)}
              alt="img-fluid"
            />
            <div className="title green">{item.time}</div>
            <div
              className="mobile-text"
              dangerouslySetInnerHTML={createMarkup(item.text)}
            />
          </div>
        ))}
      </DeviceDetect.UniversalTable>
    </div>
  );
};

export default Timeline;
