import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BtnArrow from '../images/about-us/btn.inline.svg';

class MapAboutCarousel extends React.Component {
  constructor(props) {
    super(props);
    const { markers } = props;
    this.state = {
      markers: markers,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const sliderSettings = {
      arrows: false,
      infinite: true,
      swipeToSlide: true,
      slidesToShow: 3,
      dots: false,
      autoplay: true,
      autoplaySpeed: 10000,
    };
    const { markers } = this.state;
    let countries = [];
    const imageContext = require.context('../images/about-us', true);
    markers.map((region) => {
      return region.countries.map((item) => {
        return countries.push(item);
      });
    });
    return (
      <div className="overflow-hidden">
        <Slider ref={(c) => (this.slider = c)} {...sliderSettings}>
          {countries.map((item, id) => (
            <div
              key={id}
              className="country-item w-100 d-flex justify-content-center align-items-center flex-column"
            >
              <img
                alt="item.icon"
                src={imageContext('./' + item.icon)}
                className="img-fluid"
              />
              <div className="text-center mt-3">{item.country}</div>
            </div>
          ))}
        </Slider>
        <div className="country-arrows">
          <div
            className="country-prev"
            onClick={this.previous}
            onKeyUp={this.previous}
            role="button"
            tabIndex={0}
          >
            <BtnArrow alt="btn_icon" />
          </div>
          <div
            className="country-next"
            onClick={this.next}
            onKeyUp={this.next}
            role="button"
            tabIndex={0}
          >
            <BtnArrow alt="btn_icon" />
          </div>
        </div>
      </div>
    );
  }
}

export default MapAboutCarousel;
