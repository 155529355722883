import React from 'react';
import MapMarker from './MapMarker';
import map from './../images/about-us/map-clean.png';
import dash from './../images/about-us/dashes-lines.png';

class MapAbout extends React.Component {
  constructor(props) {
    super(props);
    const {
      markers
    } = props;
    this.state = {
      hover: false,
      markers: markers
    };
  }
  render() {
    const { markers } = this.state;

    const listMarkers = markers.map((item) =>
      <MapMarker key={item.num} className={`marker${item.num}`} marker={item}/>
    );
    return (
      <div className="container">
        <div className="row">
          <div className="position-relative">
            <img alt="map" src={map} className="w-100"/>
            <img alt="dash" src={dash} className="dash"/>
            {listMarkers}
          </div>
        </div>
      </div>
    );
  }
}

export default MapAbout;
