import React, { useState } from 'react';
import { Background, Parallax } from 'react-parallax';
import MapAbout from '../components/AboutMap';
import ContactUsAndThanks from '../components/CombineModals/ContactUsAndThanks';
import DeviceDetect from '../components/DeviceDetect';
import Layout from '../components/Layout';
import MapAboutCarousel from '../components/MapAboutCarousel';
import SEO from '../components/Seo';
import Timeline from '../components/Timeline';
import TitleImgBlock from '../components/TitleImgBlock';
import { checkerTagScript } from '../helpers/checkerTagScript';
import img1 from '../images/about-us/img-paralax-1.png';
import img2 from '../images/about-us/img-paralax-2.png';
import i18n from '../services/translation/i18next';
import { windowSizeChecker } from '../utils';

const AboutUs = ({ pageContext: { data: dataProps } }) => {
  const isMobile = windowSizeChecker.isMobile();

  const data = i18n.t('aboutUs', { returnObjects: true });
  const imageContext = require.context('../images/about-us', true);
  const img = imageContext('./' + data.title_img);

  const createMarkup = (path) => i18n.t(path, { returnObjects: true });

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };
  const markers = i18n.t('aboutUs.markers', { returnObjects: true });

  return (
    <Layout>
      <SEO title="About us" />
      <div className="section page-services page-default about-us-wrapper">
        <TitleImgBlock
          image={img}
          title={dataProps.content_header?.title}
          alt="aboutUs"
        />
        <div className="container about-us">
          <div className="col-lg-6">
            <div className="about_title">
              {dataProps.about_description?.title}
            </div>
          </div>

          <div className="col-12 description-container">
            <div
              className="about_first_description"
              dangerouslySetInnerHTML={{
                __html: checkerTagScript(
                  dataProps.about_description?.company_description,
                  createMarkup('aboutUs.companyDescription')
                ),
              }}
            />
            <div
              className="about_second_description"
              dangerouslySetInnerHTML={{
                __html: checkerTagScript(
                  dataProps.about_description?.description,
                  createMarkup('aboutUs.description')
                ),
              }}
            />
          </div>
        </div>
        <div className="container col-12">
          <div className="statistics-section">
            {dataProps.statistics?.map((item, id) => (
              <div className=" statistics-section__item" key={id}>
                <div className="statistics-section__count">{item.count}</div>
                <div className="statistics-section__title">{item.title}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="content-section">
          <div className="container">
            <p
              className="content-section__title"
              dangerouslySetInnerHTML={{
                __html: checkerTagScript(
                  dataProps.content_body_about_us?.title,
                  createMarkup('aboutUs.content_body_about_us')
                ),
              }}
            />

            <div className="images_wrapper">
              <Parallax
                bgImageAlt=""
                strength={200}
                bgImageSize="cover"
                className="content-section__parallax-img"
                blur={0}
                disabled={isMobile}
              >
                <Background className="background_wrapper">
                  <img src={img1} alt="" />
                </Background>
              </Parallax>

              <Parallax
                bgImageAlt=""
                strength={200}
                bgImageSize="cover"
                className="content-section__parallax-img"
                blur={0}
                disabled={isMobile}
              >
                <Background className="background_wrapper">
                  <img src={img2} alt="" />
                </Background>
              </Parallax>
            </div>
          </div>
        </div>

        <Timeline stepper={dataProps.content_body_about_us?.stepper} />

        <div className="container">
          <div className="row">
            <div className="col-12  header-map-container">
              <div className="header-map">
                <div className="title-map">
                  {dataProps.Geo_block.Geo_block_title}
                </div>
                <div className="description-map">
                  {dataProps.Geo_block.Geo_block_description}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeviceDetect.UniversalDesktop>
          <MapAbout markers={markers} />
        </DeviceDetect.UniversalDesktop>
        <DeviceDetect.Mobile>
          <MapAboutCarousel markers={markers} />
        </DeviceDetect.Mobile>

        <section className="section text-center pt-0">
          <div className="container d-flex flex-column align-items-center">
            <div className="contact-us-title">
              {dataProps.content_footer?.title}
            </div>
            <div className="pt-3">
              <button
                onClick={openModalHandler}
                className="btn btn-lg btn-primary rounded-pill mt-1"
              >
                {dataProps.content_footer?.button_text}
              </button>
            </div>
          </div>
        </section>
      </div>

      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </Layout>
  );
};

export default AboutUs;
