import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { createMarkup } from '../../../../helpers/createMarkup';

const defaultKey = 0;
const delay = 10000;

const TimelineTabs = ({ stepper }) => {
  const [activeKey, setActiveKey] = useState(defaultKey);

  useEffect(() => {
    const next = () => {
      const to = (activeKey + 1) % stepper.length;
      setActiveKey(to);
    };

    const t = setTimeout(next, delay);
    return () => clearTimeout(t);
  }, [activeKey, stepper]);

  const imageContext = require.context('../../../../images/about-us', true);

  return (
    <Tab.Container
      defaultActiveKey={defaultKey}
      activeKey={activeKey}
      onSelect={(k) => setActiveKey(+k)}
    >
      <div className="col-4">
        <Nav className="flex-column">
          {stepper.map((item, index) => (
            <Nav.Item key={index}>
              <Nav.Link eventKey={index} className="tab-item pl-0">
                <div className="title">{item.time}</div>
                <div className="line line-short" />
                <div className="line line-long" />
                <div className="line line-short" />
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="col-8 d-flex align-items-center">
        <Tab.Content>
          {stepper.map((item, index) => (
            <Tab.Pane eventKey={index} key={index}>
              <div className="tab-item-content">
                <img
                  className="img-fluid"
                  src={imageContext('./' + item.icon)}
                  alt="img-fluid"
                />
                <div
                  className="description"
                  dangerouslySetInnerHTML={createMarkup(item.text)}
                />
              </div>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </div>
    </Tab.Container>
  );
};

export default TimelineTabs;
